import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import SummaryTextRow from './components/summaryTextRow/SummaryTextRow';
import SummaryTitleBlock from './components/summaryTitleBlock/SummaryTitleBlock';
import SummaryTable from './components/summaryTable/SummaryTable';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { isSectionAllowed } from '../../../helpers/privilegesHelpers';
import {
  EditTypes,
  SummaryOrdersTRow,
  SummaryOrdersTableColIds,
  SummarIndicationsTableColIds,
  ORDER_COLUMNS,
  EXIT_TIME_COLUMNS,
  SummaryTimeExitsTRow,
  SummaryTimeExitTableColIds,
  EXIT_DISTANCE_COLUMNS,
  SummaryDistanceExitTableColIds,
  SummaryDistanceExitsTRow,
  INDICATIONS_COLUMNS
} from './summaryConstants';
import { StrategyProfileShape, OrderManagementSettings } from 'interfaces/strategyProfilesShapes';
import {
  PRICE_DIRECTION_LABELS,
  SMOOTH_ALGO_LABELS,
  ATR_MINUS_1_TYPE_LABELS
} from 'constants/strategyProfilesAddConstants';
import s from './Summary.module.scss';
import { useHistory } from 'react-router-dom';
import { PAGES } from '../../../constants/routes';

interface SummaryDataContentProps {
  onEditOpen: (type: EditTypes) => void;
  profile: StrategyProfileShape;
  isReadOnly: boolean;
  contentClass?: string;
}

const NO_DATA_MSG = '-';
const { ORDER, DISTANCE, SIZE, TIMER } = SummaryOrdersTableColIds;
const { TF, OPP, ATR, LE_TF, LE_PERIOD } = SummarIndicationsTableColIds;
const { TIME_COEFFICIENT, EXIT_BY_TIME, EXIT_TIMER } = SummaryTimeExitTableColIds;
const { EXIT_THRESHOLD, EXIT_BY_DISTANCE, DISTANCE_COEFFICIENT } = SummaryDistanceExitTableColIds;

const getEntryOrdersTData = (orderSettings: OrderManagementSettings | null): Array<SummaryOrdersTRow> => {
  if (!orderSettings) return [];
  return orderSettings.enter_orders.map(({ atr_distance_coefficient, size_percent, timer_minutes }, idx) => ({
    id: idx.toString(),
    [ORDER]: `Order ${idx + 1}`,
    [DISTANCE]: atr_distance_coefficient.toString(),
    [SIZE]: size_percent.toString(),
    [TIMER]: timer_minutes.toString()
  }));
};

const getIndicationsTData = (indicationsSettings) => {
  if (!indicationsSettings) return [];
  return indicationsSettings.map(({ le_settings, opp_settings }, idx) => ({
    id: idx.toString(),
    [TF]: opp_settings.is_enabled ? opp_settings.timeframe.toString() : '-',
    [OPP]: opp_settings.is_enabled ? opp_settings.opp_sp : '-',
    [ATR]: opp_settings.is_enabled ? opp_settings.opp_atr_t_percent : '-',
    [LE_TF]: le_settings.is_enabled ? le_settings.timeframe.toString() : '-',
    [LE_PERIOD]: le_settings.is_enabled ? le_settings.periods_amount : '-'
  }));
};

const getTimeExitsTData = (orderSettings: OrderManagementSettings | null): Array<SummaryTimeExitsTRow> => {
  if (!orderSettings || !orderSettings?.exit_timer_coeff_settings) return [];
  return orderSettings.exit_timer_coeff_settings.map(
    ({ change_exit_coefficient_by_time, change_exit_timer_minutes }, idx) => ({
      id: idx.toString(),
      [EXIT_BY_TIME]: `Timer ${idx + 1}`,
      [TIME_COEFFICIENT]: change_exit_coefficient_by_time.toString(),
      [EXIT_TIMER]: change_exit_timer_minutes.toString()
    })
  );
};

const getDistanceExitsTData = (orderSettings: OrderManagementSettings | null): Array<SummaryDistanceExitsTRow> => {
  if (!orderSettings || !orderSettings?.exit_d_threshold_settings) return [];
  return orderSettings.exit_d_threshold_settings.map(
    ({ change_exit_d_threshold_by_distance, change_exit_coefficient_by_distance }, idx) => ({
      id: idx.toString(),
      [EXIT_BY_DISTANCE]: `Distance ${idx + 1}`,
      [EXIT_THRESHOLD]: change_exit_d_threshold_by_distance.toString(),
      [DISTANCE_COEFFICIENT]: change_exit_coefficient_by_distance.toString()
    })
  );
};

const SummaryDataContent = ({
  onEditOpen,
  profile: { title, mm_profile, settings, ticker_configs_name },
  contentClass,
  isReadOnly
}: SummaryDataContentProps): JSX.Element => {
  const { tickers_configs } = usePrivileges();
  const { push } = useHistory();
  const signalSettings = settings?.signal_settings;
  const orderSettings = settings?.order_management_settings;
  const indicationsSettings = settings?.indications_settings;

  const entryOrdersTData = getEntryOrdersTData(orderSettings);
  const indicationsTData = getIndicationsTData(indicationsSettings);
  const timeExitsTData = getTimeExitsTData(orderSettings);
  const distanceExitsTData = getDistanceExitsTData(orderSettings);

  return (
    <div className={cn(s.content, { [contentClass]: contentClass })}>
      <div className={s.profileCol}>
        <div className={s.profileContent}>
          <SummaryTitleBlock
            title="Strategy profile"
            onEditClick={() => onEditOpen(EditTypes.PROFILE)}
            isReadOnly={isReadOnly}
          />

          <SummaryTextRow label="Strategy profile name" value={title} />
          <SummaryTextRow label="MM Profile" value={mm_profile?.title ?? NO_DATA_MSG} />
          <SummaryTextRow
            label="Tickers list name:"
            value={ticker_configs_name}
            onLinkClick={isSectionAllowed(tickers_configs) ? () => push(PAGES.STRATEGY_PROFILES_TICKERS) : null}
          />
        </div>

        <div className={s.paramsContent}>
          <SummaryTitleBlock
            title="Key parameters"
            onEditClick={() => onEditOpen(EditTypes.PARAMETERS)}
            isReadOnly={isReadOnly}
          />

          <SummaryTextRow label="Time-frame" value={signalSettings?.timeframe ?? NO_DATA_MSG} />
          <SummaryTextRow
            label="Price direction"
            value={
              signalSettings?.price_direction ? PRICE_DIRECTION_LABELS[signalSettings.price_direction] : NO_DATA_MSG
            }
          />
          <SummaryTextRow
            label="SP (start point, in ATR)"
            value={signalSettings?.start_point_coefficient ?? NO_DATA_MSG}
          />
          <SummaryTextRow label="ROC timer (for SP, min)" value={signalSettings?.timer_minutes ?? NO_DATA_MSG} />
          <SummaryTextRow label="ATR threshold (%)" value={signalSettings?.atr_threshold_percents ?? NO_DATA_MSG} />
          <SummaryTextRow
            label="Smoothing (for ATR)"
            value={signalSettings?.atr_smooth_algo ? SMOOTH_ALGO_LABELS[signalSettings.atr_smooth_algo] : NO_DATA_MSG}
          />
          <SummaryTextRow label="N-periods (for ATR)" value={signalSettings?.atr_n_periods ?? NO_DATA_MSG} />
          <SummaryTextRow
            label="Signal max duration (min)"
            value={signalSettings?.signal_duration_minutes ?? NO_DATA_MSG}
          />
          <SummaryTextRow
            label="ATR-1 type"
            value={
              signalSettings?.atr_minus_1_type ? ATR_MINUS_1_TYPE_LABELS[signalSettings.atr_minus_1_type] : NO_DATA_MSG
            }
          />
          <SummaryTextRow label="ATR-1 value" value={signalSettings?.atr_minus_1_value ?? NO_DATA_MSG} />

          <SummaryTitleBlock title="Activation Signals from Queue" onEditClick={() => null} isReadOnly />
          <SummaryTextRow label="TR in ATR" value={signalSettings?.tr_in_atr_threshold ?? NO_DATA_MSG} />
          <SummaryTextRow label="Revert" value={signalSettings?.revert_threshold ?? NO_DATA_MSG} />

          <SummaryTitleBlock
            title="Indications"
            onEditClick={() => onEditOpen(EditTypes.INDICATIONS)}
            isReadOnly={isReadOnly}
          />
          {!isEmpty(indicationsTData) && <SummaryTable rows={indicationsTData} columns={INDICATIONS_COLUMNS} />}
        </div>
      </div>

      <div className={s.orderCol}>
        <SummaryTitleBlock
          title="Order management"
          onEditClick={() => onEditOpen(EditTypes.ORDER_MANAGEMENT)}
          isReadOnly={isReadOnly}
        />

        <div className={s.ordersSubBlock}>
          <h3 className={cn(s.ordersSubtitle, 'default-text')}>Entry orders</h3>
          <SummaryTextRow label="Quantity of orders" value={orderSettings?.enter_orders?.length ?? NO_DATA_MSG} />

          {!isEmpty(entryOrdersTData) && <SummaryTable rows={entryOrdersTData} columns={ORDER_COLUMNS} />}

          <SummaryTextRow
            label="Future price (in ATR)"
            value={orderSettings?.future_price_coefficient ?? NO_DATA_MSG}
          />
          <SummaryTextRow label="ATR timer (in min)" value={orderSettings?.exit_orders?.atr_timer ?? NO_DATA_MSG} />
          <SummaryTextRow
            label="Change of ATR (%)"
            value={orderSettings?.exit_orders?.change_of_atr_percentage ?? NO_DATA_MSG}
          />
        </div>

        <div className={s.ordersSubBlock}>
          <h3 className={cn(s.ordersSubtitle, 'default-text')}>Exit orders</h3>
          <SummaryTextRow
            label="Exit order place (in ATR)"
            value={orderSettings?.exit_orders?.exit_order_place_coefficient ?? NO_DATA_MSG}
          />
          <SummaryTextRow
            label="Size timer (min)"
            value={orderSettings?.exit_orders?.size_timer_minutes ?? NO_DATA_MSG}
          />
          <SummaryTextRow
            label="Change of high/low (%)"
            value={orderSettings?.exit_orders?.high_low_change_percentage ?? NO_DATA_MSG}
          />
          <SummaryTextRow
            label="Change of high/low timer (min)"
            value={orderSettings?.exit_orders?.high_low_change_timer ?? NO_DATA_MSG}
          />

          {!isEmpty(timeExitsTData) && <SummaryTable rows={timeExitsTData} columns={EXIT_TIME_COLUMNS} />}
          {!isEmpty(distanceExitsTData) && <SummaryTable rows={distanceExitsTData} columns={EXIT_DISTANCE_COLUMNS} />}
        </div>
      </div>
    </div>
  );
};

export default SummaryDataContent;
